/* Colors */
#intro {
  padding-top: 8rem; }
  @media (min-width: 576px) {
    #intro {
      padding-top: 4rem; } }
  @media (min-width: 768px) {
    #intro {
      padding-top: 8rem; } }
  @media (min-width: 1280px) {
    #intro {
      padding-top: 12.5rem; } }

@media (min-width: 768px) {
  #header {
    display: grid;
    grid-template-columns: 5rem auto; } }

@media (min-width: 992px) {
  #header {
    grid-template-columns: 6rem auto; } }

@media (min-width: 1600px) {
  #header {
    grid-template-columns: 8rem auto; } }

#header-logo {
  width: 3rem;
  height: 3rem;
  display: block;
  margin: 0 auto; }
  @media (min-width: 768px) {
    #header-logo {
      margin-left: 0;
      margin-top: .1rem; } }
  @media (min-width: 992px) {
    #header-logo {
      width: 4rem;
      height: 4rem;
      margin-top: 1.1rem; } }
  @media (min-width: 1600px) {
    #header-logo {
      width: 6rem;
      height: 6rem;
      margin-top: 0; } }

#header-text h3 {
  margin-bottom: 4rem; }

@media (max-width: 767.98px) {
  #header-text h1, #header-text h3 {
    text-align: center; } }

@media (min-width: 768px) {
  #header-text h3 {
    margin-bottom: 6rem; } }

@media (min-width: 1280px) {
  #header-text h3 {
    margin-bottom: 8rem; } }

@media (min-width: 992px) {
  #portfolio .button.block {
    margin-top: .5rem;
    grid-column-start: 2; } }

#stack {
  padding-top: 0; }
  #stack h2 {
    font-size: 1.5rem;
    line-height: 2rem; }
    @media (min-width: 992px) {
      #stack h2 {
        font-size: 2.5rem;
        line-height: 3rem; } }

.tech-stack {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
  padding-top: 2rem;
  margin: 0 -1rem; }
  @media (min-width: 992px) {
    .tech-stack {
      justify-content: space-between; } }
  .tech-stack img {
    display: block;
    height: 3rem;
    width: auto;
    margin: 0 1rem;
    margin-bottom: 2rem; }
  @media (max-width: 767.98px) {
    .tech-stack #rails-icon {
      display: none; } }
  @media (min-width: 992px) and (max-width: 1179.98px) {
    .tech-stack #rails-icon {
      display: none; } }

.swiper-container {
  margin: 0 -50vw !important;
  padding: 0 50vw !important; }
  .swiper-container .swiper-wrapper {
    margin-bottom: 2rem; }
    @media (max-width: 991.98px) {
      .swiper-container .swiper-wrapper {
        margin-bottom: 1rem; } }
  .swiper-container .swiper-pagination {
    bottom: 0; }
    .swiper-container .swiper-pagination .swiper-pagination-bullet-active {
      background: white;
      opacity: .85; }
  @media (max-width: 991.98px) {
    .swiper-container .card::before {
      padding-top: 100%; } }
